<template>
  <div>
    <StatFilter PERIOD="daily" :STORE_OPTS="branch_list" :COUNTER_OPTS="counter_list" @query="onQueryBI" />
    <CRow v-if="load_done">
      <CCol md="6" lg="4">
        <CardSimpleNumber :header="$t('cloud.title.gender_male')" :text="`${stats_today_male}`">
          <h4 :class="{'text-success':todayMaleIncrease >= 0,'text-danger':todayMaleIncrease<0}">
            <svgicon :name="todayMaleIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{Math.sqrt(todayMaleIncrease*todayMaleIncrease)}}</span>
          </h4>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="4">
        <CardSimpleNumber :header="$t('cloud.title.gender_female')" :text="`${stats_today_female}`">
          <h4 :class="{'text-success':todayFemaleIncrease >= 0,'text-danger':todayFemaleIncrease<0}">
            <svgicon :name="todayFemaleIncrease >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{Math.sqrt(todayFemaleIncrease*todayFemaleIncrease)}}</span>
          </h4>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="4">
        <CardSimpleNumber :header="$t('cloud.title.age_most_visit')" :text="`${stats_today_age_most}`">
          <h4 class="text-secondary">
            {{$t('cloud.title.day_yesterday')}} : {{stats_yesterday_age_most}}
          </h4>
        </CardSimpleNumber>
      </CCol>
      <CCol md="12" lg="6">
        <CardPieCharts
          :title="$t('cloud.title.gender_distribution')"
          :stats="[stats_today_male, stats_today_female]"
          :labels="[$t('cloud.title.gender_male'), $t('cloud.title.gender_female')]"
          :colors="['#358ec4', '#e65054']"
        />
      </CCol>
      <CCol md="12" lg="6">
        <CardPieCharts
          :title="$t('cloud.title.visitor_age')"
          :stats="stats_today_age_counts"
          :labels="AGE_STR"
          :colors="['#f66d44', '#feae65', '#dece85', '#aadea7', '#64c2a6', '#2d87bb']"
        />
      </CCol>
    </CRow>
    <div v-loading="!load_done" v-if="!load_done" />
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import StatFilter from './StatFilter.vue'
import CardSimpleNumber from '@/components/cards/SimpleNumber.vue'
import CardPieCharts from '@/components/cards/PieCharts.vue'

export default {
  name: 'RetailVisitAdvancedAnalytics',

  components: {
    StatFilter,
    CardSimpleNumber,
    CardPieCharts
  },

  data() {
    return {
      load_done: false,
      loading: {
        counter: true,
        branch: true,
        gender: true,
        age: true
      },
      filters: null,
      branch_list: [],
      counter_list: [],
      stats_today_male: 0,
      stats_today_male_yesterday: 0,
      stats_today_female: 0,
      stats_today_female_yesterday: 0,
      stats_today_age_total: 1,
      stats_today_age_most: '30-40',
      stats_today_age_counts: null,
      stats_yesterday_age_most: '30-40',

      AGE_STR: ['0-20', '20-30', '30-40', '40-50', '50-60', '60-']
    }
  },
  computed: {
    todayMaleIncrease() {
      return (this.stats_today_male - this.stats_today_male_yesterday);
    },
    todayFemaleIncrease() {
      return (this.stats_today_female - this.stats_today_female_yesterday);
    },
  },
  mounted() {
    this.initFetch();
  },
  methods: {
    initFetch() {
      this.getCounterList();
      this.getBranchList();
      // this.onQueryBI();
    },
    getCounterList() {
      let query_string = '';
      const params = {
        ipp: 500,
        search: this.searchText,
        page: this.pageCurrent,
      };
      if (this.filters && this.filters.branch) {
        params.branch = this.filters.branch;
      }
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      this.loading.counter = true;
      axios.get(`/api/bi/genders/${query_string}`)
        .then(result => {
          this.counter_list = result.data.list;
        })
        .catch(e => {
          console.error(e);
        })
        .finally(_ => {
          this.loading.counter = false;
          this.checkLoading();
        })
    },
    getBranchList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 500, // FIXME. 난중에 하자...
        page: 1,
        opt: 'branch',
        geo_info: 0
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      this.loading.branch = true;
      axios.get(`/api/bi/stores/${query_string}`)
        .then(result => {
          this.branch_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          // this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          this.loading.branch = false;
          this.checkLoading();
        })
    },
    onQueryBI(stat_filter) {
      this.load_done = false;
      this.filters = stat_filter;
      if (this.filters && this.filters.date) {
        // this.statTrafficAround();
        // this.statCustomerVisit();
        // this.statBusiestHour();
        this.statGender();
        this.statAge();
      }
      this.getCounterList();
    },
    buildBIQs(date_before) {
      let query_string = '';
      const params = {};
      if (this.filters) {
        params.branch = this.filters.branch;
        params.counter = this.filters.counter;
        if (this.filters.date) {
          this.filters.date.setHours(0);
          this.filters.date.setMinutes(0);
          this.filters.date.setSeconds(0);
          this.filters.date.setMilliseconds(0);
          params.sts = this.filters.date.getTime();
          params.ets = params.sts + (1000 * 60 * 60 * 24);

          if (date_before) {
            const delta = date_before * 24 * 60 * 60 * 1000;
            params.sts = params.sts - delta;
            params.ets = params.ets - delta;
          }
        }
      }
      if (params) {
        query_string = '?' + qs.stringify(params);
      }
      return query_string;
    },
    statGender() {
      const query_string = this.buildBIQs();
      const query_string_yesterday = this.buildBIQs(1);
      let loading_today = true;
      let loading_yesterday = true;

      this.loading.gender = true;
      axios.get(`/api/bi/statistics/gender/${query_string}&is_today=1`)
        .then(result => {
          this.stats_today_male = result.data.male_cnt;
          this.stats_today_female = result.data.female_cnt;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_today = false;
          if (!loading_yesterday) {
            this.loading.gender = false;
            this.checkLoading();
          }
        })
      axios.get(`/api/bi/statistics/gender/${query_string_yesterday}&is_today=1`)
        .then(result => {
          this.stats_today_male_yesterday = result.data.male_cnt;
          this.stats_today_female_yesterday = result.data.female_cnt;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_yesterday = false;
          if (!loading_today) {
            this.loading.gender = false;
            this.checkLoading();
          }
        })
    },
    statAge() {
      const query_string = this.buildBIQs();
      const query_string_yesterday = this.buildBIQs(1);
      let loading_today = true;
      let loading_yesterday = true;
      this.loading.age = true;
      axios.get(`/api/bi/statistics/age/${query_string}&is_today=1`)
        .then(result => {
          const cnts = result.data.counts;
          const max_val = Math.max(...cnts);
          const max_index = cnts.findIndex(el => el === max_val);
          this.stats_today_age_most = this.AGE_STR[max_index];
          this.stats_today_age_counts = result.data.counts;
          this.stats_today_age_total = Math.max(1, result.data.total_cnt);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_today = false;
          if (!loading_yesterday) {
            this.loading.age = false;
            this.checkLoading();
          }
        })
      axios.get(`/api/bi/statistics/age/${query_string_yesterday}&is_today=1`)
        .then(result => {
          const cnts = result.data.counts;
          const max_val = Math.max(...cnts);
          const max_index = cnts.findIndex(el => el === max_val);
          this.stats_today_age_most = this.AGE_STR[max_index];
          // this.stats_today_age_counts = result.data.counts;
          // this.stats_today_age_total = Math.max(1, result.data.total_cnt);
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_yesterday = false;
          if (!loading_today) {
            this.loading.age = false;
            this.checkLoading();
          }
        })
    },
    checkLoading() {
      if (!this.loading.branch && !this.loading.counter && !this.loading.gender && !this.loading.age) {
        setTimeout(() => {
          this.load_done = true;
        }, 100);
      }
    }
  }
}
</script>